/* eslint-disable no-undef */
/* eslint-disable react/jsx-key */
import { HistoryOutlined } from "@ant-design/icons";
import moment from "moment";
let lastYear = moment().subtract(1, 'years').format("YYYY");
let year = moment().year();
const currentDate = moment();
// Calculate the start date of the current month
const currentMonthStartDate = currentDate.clone().startOf('month');
const constants = {
	//Frontend version
	feVersion:"1.0.48.7",
	//
	// This gap is required to prevent merge conflicts.
	//
	//Frontend build
	feBuild:"161.15",//Should be changed only when increasing the tag version of develop branch
	//
	// This gap is required to prevent merge conflicts.
	//
	// Common constants
	ViewAgain: "Click to view again",
	InProgress:"In Progress.",
	CategoryShortFPP: "FPP",
	CategoryShortPIET: "PIET",
	CategoryShortSAED: "SAED",
	CategoryMixed: "Mixed",
	CategoryFPP: "Foundation and Professional Practice (FPP)",
	CategoryPIET: "Planning, Implementation and Evaluation of Treatment (PIET)",
	CategorySAED: "Screening, Assessment, Evaluation, and Diagnosis (SAED)",
	CategoryTest: "Category Tests",
	TopicTest: "Topic Tests",
	TopicTitle:"Topic",
	CustomTest: "Custom Tests",
	TopicTutorials: "Topic Tutorials", //DL
	CategoryTutorials: "Category Tutorials", //DL
	CustomTutorials: "Custom Tutorials", //DL
	FullLengthTests: "Full Length Tests",
	//Message Time
	MessageDisplayLong:8,
	// Dashboard
	Flashcards: "Flashcards",
	Tutorials: "Tutorials",
	MyTests: "My Tests",
	MyNotes: "My Notes",
	ExamDateHeader: "PRAXIS – SLP",
	ProgressOnTests: "Progress on Tests",
	ViewCompareBTN: "Compare your scores",
	ClickQODAnswer:"Click to answer",
	ViewDayQuestion:"Question of the Day",
	Great: "Great going!",
	BeginLearning:"Begin learning to start your Streak!",
	ReferFriend: "Refer a friend",
	TakeTest:"Take a Test",
	FeedbackBTN: "Send us feedback",
	FeedbackSucess: "Thank you for your feedback.",
	FeedbackOptionError: "Choose an option",
	FeedbackMessageError: "Enter a message",
	FeedBackCommentRequired: "Enter comment",
	RevealBTN: "Reveal",
	PlannedExamDateSUC: "Planned exam date updated successfully",
	SelectExamDate:"Select Exam Date",
	PlannedExamDate:"Planned exam date",
	ReachLevel5:" points more to reach Level 5 CHAMPION",
	ReachLevel0:"Earn points to reach Level 1",
	ReachLevelText:" points needed to reach Level ",
	Level6Message:"Wonderful!! You are an ace.",
	Level1:"Level 1",
	Level2:"Level 2",
	Level3:"Level 3",
	Level4:"Level 4",
	Level5:"Level 5",
	Levels:{
		0:"Level 0",
		1:"Level 1",
		2:"Level 2",
		3:"Level 3",
		4:"Level 4",
		5:"Level 5",
	},
	// Login
	SignUpRedirect: "Don’t have an account?",
	SignUpRedirectLink: "Sign up now!",
	EmailRedirect: "Didn't receive the activation email?",
	ResendLink: "Click here to resend it.",
	LoginHeader: "Login to your account",
	AdminHeader:"Admin Portal",
	ResendEmail:"Resend activation email",
	ResendEmailSubTitle:"If you did not receive an email to activate your account, enter any one of the following details to receive an activation email again.",
	RememberMe: "Remember Me",
	ForgotPsdRedirectLink: "Forgot password?",
	LoginBTN: "Login",
	LoginRestriction:"SLP - PRAXIS test application was opened in another window.",
	LoginRestrictionPart2:'Click "Use Here" button to continue using the application in this window.',
	// Registration
	LoginRedirect: "Already have an account?",
	LoginRedirectLink: "Click here to Login!",
	RegisterHeader: "Register",
	PsdTip:
		"Password length should be minimum 8 characters. It must contain at least 1 uppercase letter, 1 lowercase letter, a number, any special character and no spaces.",
	AlternateEmailTip:
		"Provide an alternate email address. If you do not receive any emails from advancedreviewpractice.com, your alternate email address will be used to contact you.",
	PrimaryEmailTip:"All communication from the site will be sent to this email address."	,
	BookCodeTip:"The print version of the book will include the access code inside the front cover. The e-book will include a link and an access code. Your subscription to the online resources (e.g. flashcards, online practice tests, etc.) will be valid for 1 year only from the date of registration. Enter the access code exactly as given in the book including the dashes (e.g. 12345-ABCDE-67890-FGHIJ-54321).",
	BookCodeSubText:"Your subscription to the online resources (e.g. flashcards, online practice tests, etc.) will be valid for 1 year only from the date of registration.",
	TnCRead: "I have read and agree to the",
	RemindersText:[<span>Email communications will be sent to your registered email address from PRO-ED related to your <i>Advanced Review for Speech-Language Pathology</i> practice test results. You may also receive reminders about this product, practice tests, and other features. You can change the frequency or unsubscribe from these emails at any time by accessing your account or by clicking the “Manage my email preferences” link in these emails. Note that if you unsubscribe, you may still receive emails from us related to registration and your account. See our privacy policy for more details.</span>],
	MyProfileTnCRead:"I have read, agreed and understood the following Pro-Ed documents:",
	TnCReadLink: "Terms of Use ",
	EULALink:"End User License Agreement",
	PrivacyPolicy:"Privacy Policy",
	CancelBTN: "Cancel",
	RegisterBTN: "Register",
	BookCodeMoreInfo:"/how-do-i-find-the-access-code-to-register-for-the-practice-exams/",
	RegistrationInvalidPromoExtra:"for more information.",
	RegistrationUsernameTooltip:"Username must be unique and only one word of two or more characters.",
	emailVaidationError:"Sorry, only letters (a-z), numbers (0-9), and periods (.) are allowed.",
	TermsAndConditions:"Terms and Conditions",
	TermsAndConditionsPopup:"Please provide your consent to proceed",
	//My Profile
	MyProfileHeader: "My Profile",
	ProfileUpdateBTN: "Update",
	NewPassword: "New Password",
	ConfirmNewPassword: "Confirm new password",
	ConfirmNewPasswordMessage:"Confirm your new password",
	ReminderEmail:"REMINDER EMAIL NOTIFICATION",
	ReminderEmailNotificationTooltip:"Weekly reminder email notification will be sent once in a week. Biweekly notification will be sent once in two weeks. Monthly notification will be sent once in a month. Uncheck to stop receiving notification emails.",
	EmailTestResultsTooltip:"Results will be emailed to you after you complete the test. Uncheck to stop receiving result emails.",
	EmailTestResults:"EMAIL ME MY TEST RESULTS",
	RequiredField:"This field is required",
	ProfileUpdateSUC:"Profile updated successfully.",
	PasswordNew:"Enter new password",
	PasswordNotMatch:"The two passwords that you entered do not match!",
	EmailNotValid:"Not a valid E-mail!",
	AlternateEmailSameAsEmail:"Alternate email cannot be the same as primary email!",
	EmailSameAsAlternateEmail:"Email cannot be the same as alternate email!",
	// Forgot Password
	ForgotPsdHeader: "Forgot your password? Don't Panic!",
	ForgotPsdSubHeader:
		"Type the email address linked to your account and we'll send your password reset instructions. The email might end up in your spam folder, so make sure to check there as well.",
	SendInstructionsBTN: "Send Instructions",
	// Reset Password
	EnterUserName: "Enter username", //AL - DL
	// Labels 
	Username: "Username", 
	UsernameEmail: "Username / Email",
	Password: "Password",
	FirstName: "First name",
	LastName: "Last name",
	Details: "Details",
	Type: "Type",
	Status: "Status",
	ContactNo: "Contact Number",
	ConfirmPsd: "Confirm Password",
	BookCode: "Book code",
	Email: "Primary Email",
	AlternateEmail: "Alternate Email",
	Role: "Role",
	IamA: "I am a",
	Occupation: "Occupation",
	Country: "Country",
	USA: "United States of America",
	University: "University Attending / Attended",
	UniversityName:"University Name",
	// ScheduledMaintenance:"Scheduled Maintenance",
	// SMMessage:["Scheduled downtime on Thursday, April 27th 2023, between 3 am EST to 6 am EST. Ensure that you complete your work and logout before 3 am.",<br/>,"Please bear with us while we complete the work."],
	// ImportantNotice:"Advanced Review Practice will be undergoing scheduled maintenance on Thursday, April 27th 2023, between 3 am EST to 6 am EST. Please bear with us while we complete the work.",
	// Smart Cards
	AtaGlance: "At a glance",
	MilestoneHeader: "Milestone reached for tests",
	MilestoneFlashcardHeader: "Milestone reached for flashcards",
	MilestoneTutorialHeader: "Milestone reached for Tutorials",
	MilestoneCategoryHeader: "Milestone reached for category tests",
	MilestoneTopicHeader: "Milestone reached for topic tests",
	MilestoneFulllengthHeader: "Milestone reached for full Length tests",
	SubscriptionExpiry:" Subscription expiry notice",
	EmailMessage:"Email Message",
	FeedbackTitle:"We value your feedback",
	FeedbackMessage:"to provide feedback to help us improve the product.",
	// Smart Card Pause Test
	TestPaused: "PAUSED TESTS",
	PauseCountOne:"1",
	PauseCounttwo:"2",
	PauseCountthree:"3",
	ShowPauseCard:"0",
	ViewPauseCard1:"Click to view your paused test.",
	ViewmorePauseCard:"Click to view your paused tests.",
	ViewPauseCard3Message:"You need to complete at least one paused test before attempting another test.",
	// PauseMessage1:"You have",
	// PauseMessage2:"Test paused",
	// PauseMessage3:"Tests paused",
	ResumePauseTest:"Click to view your paused test.",
	// Refer a friend
	ReferFrdHeader:
		"If you liked the book/online resources, please consider referring friends.",
	SubmitBTN: "Submit",
	ReferFrdNote:
		"As a special Thank You, for every friend who purchases the book/online tests with the email address that you provided, you will receive a 5% discount coupon on any future Pro-Ed item purchased within a year of receiving the coupon.",
	FrdName: "Person you are referring",
	FrdEmail: "Email address",
	// Feedback
	FeedbackHeader: "SEND US FEEDBACK",
	FeedbackQ1: "How did you hear about the book?",
	HearAbtBookOption1: "Required for a course",
	HearAbtBookOption2: "Amazon",
	HearAbtBookOption3: "Recommended for a course",
	HearAbtBookOption4: "Internet",
	HearAbtBookOption5: "Through a friend/colleague",
	HearAbtBookOption6: "Social media",
	HearAbtBookOption7: "Other",
	HearAbtBookOption8: "ASHA conference",
	HearAbtBookOption9: "State conference",
	FeedbackQ2: "The tests were challenging",
	TestRatingAgree: "Agree",
	TestRatingUndecided: "Undecided",
	TestRatingDisagree: "Disagree",
	FeedbackQ3: "Overall rating of the entire product (book and online resources)",
	ProductRatingExcellent: "Excellent",
	ProductRatingVeryGood: "Very Good",
	ProductRatingGood: "Good",
	ProductRatingFair: "Fair",
	// Compare Scores
	SelfScoreComp: "Self Score Comparison",
	TopTen: "TOP 10 FULL LENGTH TEST SCORES",
	TopHundred: "Top 100 Full length Test Scores",
	Leaderboard: "Leaderboard",
	compareTestsInfoToolTip:"Latest top test scores for the last 15 days",
	compareLeaderboardInfoTooltip:"List of recent leaders",
	compareScoreFullHeader:"Self Score Comparison (Full Length tests)",
	// Choose a test
	ChooseTestHeader: "Select a Test",
	FullLengthInstructions:
		"These are timed tests of two hours and thirty minutes each. You will not be able to pause these tests.",
	TwentyFiveQuestionTimerText:"30 minutes",
	pausedClicktoResume: "Click to resume",
	retakeClickToRetakeTest:"Click to retake ",
	FiftyQuestionTimerText:"60 minutes",
	pauseCountErrorTittle:"You have paused 3 tests.",
	yourPauseTest: "paused",
	yourRetakeTest:"You have ",
	pauseCountErrorContent:"You need to complete at least one paused test before attempting another test.",
	pauseCountErrorContentpart:"to view your paused Tests.",
	minimumPauseCount:3,
	// INSTRUCTIONS
	Instructions: "Instructions",
	InstructionsSubText:"You can take the following categories of tests:",
	InstructionsWarning:
		"Before taking each test, clear the cache or browser history and do not enable the private or incognito settings on your browser. Then, close all browser windows, reopen the browser, and take a test.",
	InstructionsNote:
		"Do not click the browser’s back button in the Test Score section. This will result in the test restarting, and the results from the last completed test will be lost.",
	AboutTheTest: "About The Test",
	TopicsAndQuestions: "Topics and Questions",
	TnQInstructions: "Within the categories, there are numerous questions from the 22 topics. Click to view the Topics.",
	SelectingATest: "Selecting a Test",
	SelectCategoryTestInstructions: "Category Test: You can take a 25-question test in 30 minutes or a 50 question test in 60 minutes.",
	SelectTopicTestInstructions: "Topic Test: You can take a 25-question test in 30 minutes.",
	SelectFLTestInstructions: "Full-length Test:  Each full length test includes 132 questions. You will have 2 hours and 30 minutes to take each full length test. ",
	SelectTestInstructions2:"We recommend you set aside additional time to review your answers.",
	SelectTestInstructions3: "The full length tests are very similar to the testing format of the actual PRAXIS exam. Devoting a 3-hour time block to complete a test in its entirety will allow you to simulate taking the PRAXIS exam.",
	AnsweringATest: "Answering a Test",
	AnsweringATestInstructions: "Most questions have only one correct answer. However, there are a few multiple choice questions for which you must choose more than one item to obtain the correct answer. There may also be sequencing or drag-and-drop questions. For questions with images, click the image to enlarge it.",
	SelectTimeTest: "When you start a test, you can choose between a Timed or an Un-timed mode; however all Full Length tests can only be taken as timed tests.",
	QuestionIndexInst: "For quick navigation to any questions that you have previously answered in the test, click the Question Index button. A list of questions will be displayed. Click the question you wish to view.",
	TimerInst: "A timer will display the time remaining for the entire test. Click the icon to hide/display the timer.",
	DragInst:"Drag this icon (displayed in front of the option) and drop it in the appropriate blank space of the question or required location (for arranging a sequence).",
	SearchInst: "A list of Topics related to questions already attempted will be displayed. Select the desired Topic to display related questions. Click to view any particular question where you may review/change your answer. Click the Save button to save the newly selected answer.",
	ResumeTestInst: "After changing the answer choice of a previously answered question, click the Resume Test button to continue.",
	// BackToSearchResults: "Click this button to revert back to the list of questions matching the selected search criteria.",
	StrikeThroughInst: "If you wish to narrow down the answer options, use this feature. Select an answer option and click the Strike-through icon. The answer will be struck out. To select an answer, click the answer option once again. The strike through will be cleared for that option.",
	HightlightInst: "If you wish to highlight certain text in the question for your reference, select the text you want to highlight and click on the icon.",
	MarkForLaterInst:"If you are not sure of an answer and would like to review it again later, click this icon. Questions that are marked for later will be displayed at the end of the test. You may then select your final answer. If you do not select any answer(s), the questions will be scored as Incorrect. ",
	NotesInst: "Click the Notes icon to add a note. Enter your notes in the pop up that appears and click Save. After completing the test, visit My Notes page to view your notes. Notes can be made only during the Category and Topic tests. ",
	NavigateInst: "You will have the option of going back to previous questions. Click the Previous button to navigate to prior questions and the Next button to navigate to the next question. ",
	PauseTestInst: "You can pause a test and attempt it later. Click the Pause Test button to pause the test. You will be able to pause up to three tests. You can resume the test later from My Tests page. Full Length tests cannot be paused.",
	CompleteTestInst: "Once you have completed answering all questions, click the Submit Test button. In case you marked any questions for later, these will be displayed for you to select your final answers. Once done, click the Submit Test button.",
	ReviewTestInst: " After submitting the test, you may click Check to review your answers. Once reviewed, click the Submit Test button to view your scores. ",
	QuitTestInst: "You may quit a Full Length test at any time by clicking the   button. The test will not be scored. This feature is not available for Category and Topic tests. ",
	// After completing
	TestResultInst: "After completing a test, your score will be displayed on the Test Results page. You can download the results in PDF format.  These results will also be emailed to your registered email address.",
	ResumeTestAfter: "You may resume a paused test at any time. Visit My Tests page and click this icon to continue the test. Your time for the test will continue from when you had last paused. Full Length test cannot be paused/resumed.",
	ReviewTestAfter: "You can review the scores of any test by clicking this icon of the desired test in My Tests page. You may also review your incorrectly answered questions.",
	RestakeTestAfter: "Click this icon of the desired test in My Tests page. Only one re-take is available for each Category or Topic test. There is no re-take option for Full Length tests.",
	ImportantInst: "Before taking each test, clear the browser cache or browser history and do not enable the private or incognito settings on your browser. Then, close all browser windows, reopen the browser, and take a test.",
	ImportantInst2: "Do not Refresh or click the Browser’s back button. This will result in the test restarting and your results will be lost.",
	ImportantInst3: "Do not open the test in multiple browser windows. You will lose an attempt or it may result in your score not being saved.",
	Back: "Back",
	Next: "Next",
	SelectTestBTN: "Select a Test",
	minimumTestsSelectbtn:3,
	TakeaTestText:"Take a test",
	// Dikshas Name Constants
	//CategoryTest
	SubTitle:
		"You can take two tutorials from each category. Each tutorial will have ten questions.",
	// TopicTutorials
	TopicTutorialheading: "Topic Tutorials",
	TopicTutorialSubheading:
		"You may take one tutorial from each topic. Each tutorial will have five questions.",
	StartBtn: "Start",
	// SelectTutorial
	SelectTutorialHeading: "Tutorials",
	SelectTutorialSubheading: "Learn at your own pace.",
	AsAvailable: "(As available)",
	InProgrssTutorial: "(In Progress)",
	TestsRemaining: "tests remaining",
	TutorialsRemaining: "tutorials remaining",
	TutorialRemaining: "tutorial remaining",
	MarkForLater: "Marked for Later Tutorials",
	IncorrectAnswers: "Incorrect Answers Tutorials",
	// ResultPage
	ShareScoreBtn: "Share Score",
	ReviewIncorrectBtn: "Review Incorrect",
	// PrintResultBtn: "Download Result(PDF)",
	DownloadResultBtn: "Download Results", // MG - DL 
	EmailResultsBtn: "Email Results",
	ReviewAllBtn:"Review All",
	ReviewCorrectBtn:"Review Correct",
	//Share scor
	sharedLink:"https://advancedreviewpractice.com/",
	sharedText:"on a preparation test for the Praxis SLP and Comprehensive Examination.",
	//404
	PageNotFound:"Page Not Found",
	BackHome:"Back Home",
	SubTitle404:"Sorry, the page you visited does not exist.",
	// pause test
	PauseTestText: [
		<HistoryOutlined/>
	],
	PauseTestText2: [
		" now",
	],
	PauseTestText3:[
		"You may visit your Test History",
		<br/>,
		"to resume the Test at any time later.",
	],
	CompleteTestText: [
		"Would you like to ",
	],
	CompleteTestText3:[
		" your answers once again?",
		<br/>,
		"You will be able to change your answers, if necessary",
	],
	CompleteTestText2: [
		" to view your results.",
		<br/>,
		"An analysis of your performance will also be displayed.",
	],
	//API environment constants
	ApiUrl: process.env.REACT_APP_API_URL,
	AdminApiUrl: process.env.REACT_APP_API_ADMIN_URL,
	BaseUrl: process.env.REACT_APP_URLS_FOR_BASE,
	FeaturedImageUrl: process.env.REACT_APP_FEATURED_IMAGE_URL,
	SiteUrl:process.env.REACT_APP_WP_SITE_URL,
	EnviromentName:process.env.REACT_APP_ENV,
	//Registration form validation
	FirstNameRegistration: "Enter first name",
	FirstNameInvalid:"Invalid first name",
	LastNameRegistration: "Enter last name",
	LastNameInvalid:"Invalid last name",
	ContactNoRegistration: "Enter contact number",
	UserNameRegistration: "Enter username",
	UserNameRegistrationInvalid:"Only letters (a-z), numbers (0-9), underscore (_) and dot (.) are allowed.",
	PasswordRegistration: "Enter password",
	ConfirmPasswordRegistration: "Confirm password",
	BookCodeRegistration: "Enter book code",
	EmailRegistration: "Enter email",
	AlternateEmailRegistration: "Enter alternate email",
	RoleRegistration: "Select role",
	CountryRegistration: "Select country",
	AcceptAgreementRegistration: "You need to agree with the Terms of Use before proceeding",
	EmailReminderRegistration: "You need to agree before proceeding",
	// RegisterSuccess: "Registration completed successfully",
	RegisterSuccess: "Your account is almost ready. Please click the link in your email to activate the account. If you do not find the email in your Inbox, make sure to check your Spam folder.",
	RegistrationFailed: "Registration failed",
	OccupationRegistration:"Enter occupation",
	UniversityRegistration:"Select university",
	OtherUniversityRegistration:"Enter university name",
	OtheeUniversityExists:"University name already exists. Enter a new one.",
	OccupationRole:"Enter any occupation not displayed in the Role dropdown.",
	BookCodeValidation:"Book code should only contain numbers or letters and hyphens",
	PasswordValidation:"Password length should be minimum 8 characters. It must contain at least 1 uppercase letter, 1 lowercase letter, a number, any special character and no spaces.",
	EmailDomain:"Email should be from different email provider then the alternate email id",
	AltEmailDomain:"Alternate email should be from different email provider than the primary email id",
	MaxBookcodeChar:29,
	ConfirmRegisterTittle:"Your subscription will be valid only for 1 year from the date of registration.",
	ConfirmRegisterDescription:"Click Register if you wish to proceed now.",
	//Choose a test
	Untimed:"Untimed",
	UntimedSubTitle:"(No time limit)",
	Timed:"Timed",
	MixedTestAvailable:
		"Mixed tests will be available only after you attempt at least one test from each of the 3 categories",
	RetakeTest:"Visit My Tests to retake/review your tests.", // AL - DL
	NoRetakeTest:"You may review your tests in My Tests.",
	SelectTopicTest: "Select a topic.", // AL - DL
	//My Profile
	EmailMeTestResult:"EMAIL ME MY TEST RESULTS",
	ReminderEmailNotification:"REMINDER EMAIL NOTIFICATION",
	MyfirstName:"MY FIRST NAME MAY BE DISPLAYED ON THE WEBSITE.",
	MyfirstNameTooltip:"If your score is among the Top 10 scores globally, your name and score may be displayed in the Leaderboard and Top 10 Scores.",
	//Login
	LoginUserNameValid:"Enter Username",
	LoginPasswordValid:"Enter Password",
	//ResetPassword
	ChangePsd: "Change Password",
	BackToLogin: "Back to login",
	ResetPsd: "Reset Password",
	NewPsd: "New Password",
	ConfirmNewPsd: "Confirm New Password",
	//Full length test
	QuitTest:["Are you sure you want to quit the test?",
		<br/>,
		"Your score for this test will not be saved!"
	],
	FullLengthTestAvailable:"Full length tests will be available only after you attempt at least one test from each of the 3 categories and Mixed.",
	SelectOption:"Select an answer option or mark for later",
	SelectOptionOnly:"Select an option",
	SelectOptionDragAndDrop:"Fill all the blanks to proceed or Mark for later",
	SelectOptionDragAndDropTutorials:"Fill all the blanks to proceed",
	TestTypeNames:"ALL (FPP, PIET, SAED & MIXED)",
	LastTenMinutes:[<span>You have <b className="errorMsg">10 minutes</b>  left to complete the test.</span>],
	TenMinutesTitle:"Hurry Up!!!",
	HideTimer:"Hide Timer",
	ShowTimer:"Show Timer",
	SubmitTest:"Submit Test",
	//Short length test
	LastFiveMinutes:[<span>You have <b className="errorMsg">5 minutes</b>  left to complete the test.</span>],
	FiveMinutesTitle:"Hurry Up!!!",
	//Result page
	IncorrectTooltipMsg:"Click to view incorrectly answered questions",
	ReviewLinkToolTipMsg:"Click to review your answers",
	//Badges
	Novoice:"NOVICE",
	Achiever:"ACHIEVER",
	Scholar:"SCHOLAR",
	AllStar:"ALL STAR",
	Champion:"CHAMPION",
	//Flashcard
	SecondsPerSlide:10000,
	MoveSlidesBy:10,
	SecondsPerSlideNum:10,	
	flashcardTooltip:"when you complete this set the first time only.",
	//Admin Registration (Tittle) 
	TotalRegistration : "TOTAL REGISTRATIONS",
	Totalregistration : "Total Registrations",
	RegistrationCountry:"Registrations by Country",
	RegistrationUSUniversity:"Registrations by Universities",
	RegistrationByState:"Registrations by States (USA)",
	Top10University:"Top 10 University Registrations",
	RegistrationByRoles:"Registrations by Roles",
	QuestionRatings:"Question Ratings",
	TotalTestTaken:"Total Tests Taken",
	TotalFlashcardTaken:"Total Flashcards Taken",
	TotalTutorialTaken:"Total Tutorials Taken",
	TestScores:"Tests Taken",
	TutorialScores:"Tutorials Taken",
	FlashcardsTaken:"Flashcards Taken",
	FlashcardListing:"Flashcard Listing",
	cronSetting : "Cron Settings",
	editCronSetting : "Edit Cron Settings",
	//Registration in us universities
	BackToHome : "<<Back To Home",
	//Admin Test-listing
	TestListing :{
		verified :1,
		to_be_verified :4,
		in_progress :2,
		rejected :3,
		category_id_fpp :"1",
		category_id_saed :"2",
		category_id_piet :"3"
	},
	QuestionStatusarray:[
		{
			question_status_id:1,
			question_status_type:"Verified"
		},
		{
			question_status_id:2,
			question_status_type:"InProgress"
		},
		{
			question_status_id:3,
			question_status_type:"Rejected"
		},
		{
			question_status_id:4,
			question_status_type:"ToBeVerified"
		},
	],
	CategoryList:[
		{ 
			category_id:1,
			category_name:"FPP"
		},
		{ 
			category_id:2,
			category_name:"SAED"
		},
		{ 
			category_id:3,
			category_name:"PIET"
		}
	],
	//List of Questions (Admin side)
	ListOfQuestions :[
		{
			id:1,
			Question_sorter:"Most displayed" 
		},
		{
			id:2,
			Question_sorter :"Least displayed" 
		},
		{
			id:3,
			Question_sorter:"Most rated" 
		},
		{
			id:4,
			Question_sorter:"Least rated" 
		},
		{
			id:5,
			Question_sorter:"Most rated Difficult" 
		},
		{
			id:6,
			Question_sorter:"Least rated Difficult" 
		},
		{
			id:7,
			Question_sorter:"Most rated Medium" 
		},
		{
			id:8,
			Question_sorter:"Least rated Medium" 
		},
		{
			id:9,
			Question_sorter:"Most rated Easy" 
		},
		{
			id:10,
			Question_sorter:"Least rated Easy" 
		}
	],
	//Admin Role
	Role_type:{
		student:"Student",
		professional : "Professional",
		faculty:"Faculty",
		other:"Other",
		unspecified:"Unspecified"
	},
	//Admin Topic-wise
	TopicWiseListing :{
		category_id:{
			fpp:"1",
			saed:"2",
			piet:"3"
		},
		test_id:{
			t1:"1",
			t2:"2",
			t3:"3",
			t4:"4",
			t5:"5",
			t6:"6",
			t7:"7",
			t8:"8",
		}
	},
	// Admin total test taken
	totalTestTaken:"Total Tests Taken",
	percentagePass:62,
	percentPassForOlder:81,
	// Admin Activity Logs
	ActivityLogTitle:"Activity Logs",
	// Activity Logs – All Registrations 
	ActLogsAllRegTitle:"Activity Logs – All Registrations",
	// Student Leaderboard 
	StudentLeaderboardTitle:"Student Leaderboard",
	// Admin Code Generation
	CodeGeneration: "Code Generation",
	// admin question_listing
	QuestionListing:{
		single_select : 1,
		multi_select : 2,
		match_pair : 3,
		arrange_sequence : 4,
		drag_drop : 5,
	},
	QuestionTypearray:[
		{
			question_id:1,
			question_type:"SingleSelect"
		},
		{
			question_id:2,
			question_type:"MultiSelect"
		},
		{
			question_id:3,
			question_type:"MatchPair"
		},
		{
			question_id:4,
			question_type:"ArrangeSequece"
		},
		{
			question_id:5,
			question_type:"DragDrop"
		},
	],
	// admin FlashcardchapterTitle
	FlashcardchapterTitle:"Flashcards - Textbook",
	// admin edit student
	FeedBackTooltipTittle : "Feedback received on",
	//Date Format
	dateFormat:"MM/DD/YYYY",
	//Custom Tutorial
	custom_tutorial_subheading:"You can create three tutorials of ten questions each.",
	custom_tutorial_instructions:"Instructions: Select five or more Topics to start a tutorial.",
	number_custom_tests:3,
	number_custom_questions_required:4,
	//Review Incorrect
	ReturnToResult:"Return to Results",
	returnToQuestionList:"Return to Question list",
	ReturnToTutorials:"Return to Tutorials",
	ReturnToMyTests:"Return to My Tests",
	ReviewHeader:"Review your performance",
	ReviewHeader1:"Test Review",
	//Edit Student
	SuccessUpdate:"Student updated successfully",
	FailedUpdate:"Student update failed.",
	RoleNotSpecified:"Role not specified",
	ReviewHeaderTutorial:"Review Tutorial",
	ReviewHeaderCategoryTutorial:"Category Tutorial Review",
	ReviewHeaderTopicTutorial:"Topic Tutorial Review",
	ReviewHeaderCustomTutorial:"Custom Tutorial Review",
	OptionstoDisplay:{
		1:"A",
		2:"B",
		3:"C",
		4:"D",
		5:"E",
		6:"F",
		7:"G",
		8:"H",
		"/":"/",
	},
	OptionNumberDragAndDrop:{
		1:"option_one",
		2:"option_two",
		3:"option_three",
		4:"option_four",
		5:"option_five",
		6:"option_six",
		7:"option_seven",
		8:"option_eight",
		99:"question_text",
	},
	OptionNumbers:{
		1:"option_first",
		2:"option_second",
		3:"option_third",
		4:"option_fourth",
		5:"option_fifth",
		6:"option_sixth",
		7:"option_seventh",
		8:"option_eighth",
		99:"question_text",
		999:"case_study_content",
	},
	optionKeys : [
		'option_first',
		'option_second',
		'option_third',
		'option_fourth',
		'option_fifth',
		'option_sixth',
		'option_seventh',
		'option_eighth'
	],
	OptionPercentTest:{
		25:[
			"You’re 25% of the way through.",
			<div className="mb-3 pb-1"/>,
			"Great start on your preparation.",
			<div className="mb-3 pb-1"/>,
			"Keep going! You can do it!"
		],
		50:[
			"You’re 50% of the way through",
			<div className="mb-3 pb-1"/>,
			"Half the tests done and half to go.",
			<div className="mb-3 pb-1"/>,
			"Wonderful effort! Keep going!"
		],
		75:[
			"You’re 75% of the way through.",
			<div className="mb-3 pb-1"/>,
			"Almost there, a few more to go.",
			<div className="mb-3 pb-1"/>,
			"Great effort! Keep going!"
		],
		100:[
			"You’ve completed 100% of the available tests.",
			<div className="mb-3 pb-1"/>,
			"A job well done.",
			<div className="mb-3 pb-1"/>,
			"Super! You’re a star!!"
		]
	},
	OptionPercentFlashcard:{
		25:[
			"You’re 25% of the way through.",
			<div className="mb-3 pb-1"/>,
			"Great start on your preparation.",
			<div className="mb-3 pb-1"/>,
			"Keep going! You can do it!"
		],
		50:[
			"You’re 50% of the way through.",
			<div className="mb-3 pb-1"/>,
			"Half the flashcards done and half to go.",
			<div className="mb-3 pb-1"/>,
			"Wonderful effort! Keep going!"
		],
		75:[
			"You’re 75% of the way through.",
			<div className="mb-3 pb-1"/>,
			"Almost there, a few more to go.",
			<div className="mb-3 pb-1"/>,
			"Great effort! Keep going!"
		],
		100:[
			"You’ve completed 100% of the available flashcards.",
			<div className="mb-3 pb-1"/>,
			"A job well done.",
			<div className="mb-3 pb-1"/>,
			"Super! You’re a star!!"
		]
	},
	OptionPercentTutorials:{
		25:[
			"You’re 25% of the way through.",
			<div className="mb-3 pb-1"/>,
			"Great start on your preparation.",
			<div className="mb-3 pb-1"/>,
			"Keep going! You can do it!"
		],
		50:[
			"You’re 50% of the way through.",
			<div className="mb-3 pb-1"/>,
			"Half the tutorials done and half to go.",
			<div className="mb-3 pb-1"/>,
			"Wonderful effort! Keep going!"
		],
		75:[
			"You’re 75% of the way through.",
			<div className="mb-3 pb-1"/>,
			"Almost there, a few more to go.",
			<div className="mb-3 pb-1"/>,
			"Great effort! Keep going!"
		],
		100:[
			"You’ve completed 100% of the available tutorials.",
			<div className="mb-3 pb-1"/>,
			"A job well done.",
			<div className="mb-3 pb-1"/>,
			"Super! You’re a star!!"
		]
	},
	OptionPercentCategory:{
		25:[
			"You’re 25% of the way through.",
			<div className="mb-3 pb-1"/>,
			"Great start to your preparation.",
			<div className="mb-3 pb-1"/>,
			"Keep going! You can do it!"
		],
		50:[
			"You’re 50% of the way through.",
			<div className="mb-3 pb-1"/>,
			"Half the category tests done and half to go.",
			<div className="mb-3 pb-1"/>,
			"Wonderful effort! Keep going!"
		],
		75:[
			"You’re 75% of the way through.",
			<div className="mb-3 pb-1"/>,
			"Almost there, few more to go.",
			<div className="mb-3 pb-1"/>,
			"Great effort! Keep going!"
		],
		100:[
			"You’ve completed 100% of the available category tests.",
			<div className="mb-3 pb-1"/>,
			"A job well done.",
			<div className="mb-3 pb-1"/>,
			"Super! You’re a star!! "
		]
	},
	OptionPercentTopics:{
		25:[
			"You’re 25% of the way through.",
			<div className="mb-3 pb-1"/>,
			"Great start on your preparation.",
			<div className="mb-3 pb-1"/>,
			"Keep going! You can do it!"
		],
		50:[
			"You’re 50% of the way through.",
			<div className="mb-3 pb-1"/>,
			"Half the topic tests done and half to go.",
			<div className="mb-3 pb-1"/>,
			"Wonderful effort! Keep going!"
		],
		75:[
			"You’re 75% of the way through.",
			<div className="mb-3 pb-1"/>,
			"Almost there, a few more to go.",
			<div className="mb-3 pb-1"/>,
			"Great effort! Keep going!"
		],
		100:[
			"You’ve completed 100% of the available topic tests.",
			<div className="mb-3 pb-1"/>,
			"A job well done.",
			<div className="mb-3 pb-1"/>,
			"Super! You’re a star!! "
		]
	},
	OptionPercentFullLength:{
		25:[
			"You’re 25% of the way through.",
			<div className="mb-3 pb-1"/>,
			"Great start on your preparation.",
			<div className="mb-3 pb-1"/>,
			"Keep going! You can do it!"
		],
		50:[
			"You’re 50% of the way through.",
			<div className="mb-3 pb-1"/>,
			"Half the full-length tests done and half to go.",
			<div className="mb-3 pb-1"/>,
			"Wonderful effort! Keep going!"
		],
		75:[
			"You’re 75% of the way through.",
			<div className="mb-3 pb-1"/>,
			"Almost there, a few more to go.",
			<div className="mb-3 pb-1"/>,
			"Great effort! Keep going!"
		],
		100:[
			"You’ve completed 100% of the available full length tests.",
			<div className="mb-3 pb-1"/>,
			"A job well done.",
			<div className="mb-3 pb-1"/>,
			"Super! You’re a star!! "
		]
	},
	//Full length tests
	FullLengthTestsArray:[
		{
			id:"1", 
			test_name:"Full Length #1"
		},
		{
			id:"2", 
			test_name:"Full Length #2"
		},
		{
			id:"3", 
			test_name:"Full Length #3"
		},
		{
			id:"4", 
			test_name:"Full Length #4"
		},
		{
			id:"5", 
			test_name:"Full Length #5"
		},
		{
			id:"6", 
			test_name:"Full Length #6"
		},
		{
			id:"7", 
			test_name:"Full Length #7"
		},
		{
			id:"8", 
			test_name:"Full Length #8"
		},
		{
			id:"9", 
			test_name:"Full Length #9"
		},
		{
			id:"10", 
			test_name:"Full Length #10"
		},
		{
			id:"11", 
			test_name:"Full Length #12"
		},
		{
			id:"13", 
			test_name:"Full Length #13"
		},
	],
	//Mark for later
	PopupHeader:"Advanced Review Practice : Mark for later",
	PopupText:"You have marked question(s) for review.",
	PopupTextDescription:"Unanswered questions will be scored as incorrect.",
	PopupTextDescription2:"Click Ok to answer these question(s), or Cancel to submit the Test and display your Results.",
	//Activation
	ActivationLintExpired:"This link has expired.",
	ActivationLintGenerate:"to generate a new link for activation.",
	//Tests
	TwentyFiveQuestionTimer:1801,	//Timer for 25 Questions test of category and topic test.
	FiftyQuestionTimer:3601,	//Timer for 50 questions test of category test.
	FullLengthTimer:9001,	//2Hour 30 Minutes timer of Full length test.
	TimeOutText:"Your time for answering this test has ended.",
	TimeOutHeader:"Test Ended",
	//Case study questions popup
	CaseStudyPopupHeader:"Case Study",
	// CaseStudyPopupText:`The next few questions are based on the case being presented.
	// The case will be repeated for all these questions.
	// At the end of the case, you will be asked a different question each time the case appears.`,
	CaseStudyPopupText:[<span><p>The next few questions are based on the case being presented.
		This same case will appear above each question that pertains to it.</p></span>],
	//Test Buttons
	Previous:"Previous",
	BackToSearch:"Back to Search",
	Save:"Save",
	ResumeTest:"Resume Test",
	Quit:"Quit",
	Questions:"Question",
	columnsOfDragAndDrop: {
		[1]: { items: [] },
		[2]: { items: [] },
		[3]: { items: [] },
		[4]: { items: [] },
		[5]: { items: [] },
		[6]: { items: [] },
		[7]: { items: [] },
		[8]: { items: [] },
		[9]: { items: [] },
	},
	//Mark for later tutorial text
	MarkForLaterTutorial:"Marked for Later Tutorials",
	MLInstructions: "Review questions that you marked for later. Each tutorial will have 15 question.",
	MarkedForLaterTutorialText:[
		<p>Questions that you marked for later during practice tests will be available here.</p>,
	],
	MarkedForLaterTutorial15QuestionsAvailableText:[
		<span><span className="qust-number">15</span>questions marked for Later are ready for review.</span>
	],
	MarkedForLaterLessThen15QuestionsText:[
		<div>
			<p>There are fewer than 15 questions marked for later.</p> 
			<p>This tutorial will be available only after 15 or more questions have been marked for later.</p>
			<p>Check again after you attempt another practice test. </p>
		</div>
	],
	MarkedForLater0QuestionsText:[
		<div>
			<p>There are <span className="qust-number ml-10">0</span>questions marked for Later. </p>
			<p>This tutorial will be available only after 15 or more questions have been marked for later.</p>
			<p> Check again after you attempt another practice test.</p> 
		</div>
	],
	MarkForLaterBottomText:[
		<p>There is no time limit. You can take the tutorials at your own pace. </p>,
		<p>Once any question is answered correctly, the question will not be displayed again in this section. </p>,
		<p>After completion, you will not be able to review the tutorial again.</p>
	],
	ClickToStartBTN:"Click to start",
	//Incorrect answers tutorial
	AnswerIncorrectTutorial:"Incorrect Answers Tutorials",
	InAnsInstruction: "Review questions that you answered incorrectly. Each tutorial will have 15 question.",
	AnswerIncorrectTutorialText:[
		<p>Questions that you answered incorrectly during practice tests/tutorials will be available here.</p>,
	],
	AnswerIncorrectTutorial15QuestionsAvailableText:[
		<span><span className="qust-number">15</span>questions answered incorrectly are ready for review. </span>
	],
	AnswerIncorrectTutorial0QuestionText:[
		<div>
			<p>There are <span className="qust-number ml-10">0</span>questions answered incorrectly.</p>
			<p>This tutorials will be available only after 15 or more questions have been answered incorrectly.</p>  
			<p>Check again after you attempt any tests/tutorials. </p>
		</div>
	],
	AnswerIncorrectTutorialLessThen15QuestionText:[
		<div>
			<p>There are fewer than 15 questions marked incorrectly. </p>
			<p>This tutorials will be available only after 15 or more questions have been answered incorrectly.</p>  
			<p>Check again after you attempt any tests/tutorials. </p>
		</div>
	],
	AnswerIncorrectBottomText:[
		<p>There is no time limit. You can take the tutorials at your own pace. </p>,
		<p>Once a question is answered correctly, the question will not be displayed again in this section.</p>,
		<p>After completion, you will not be able to review the tutorials again.  </p>
	],
	QuestionTextForTutorial:"question",
	QuestionsTextForTutorial:"questions",
	minimumMarkedCount:15,
	NoteToottip:"Notes",
	MarkLaterTooltip:"Mark for Later",
	//Admin Flashcard
	QuestionRequired:"Enter question",
	AnswerRequired:"Enter answer",
	saveFailedError:"Failed to add flashcard.",
	//Case study
	//Student faq
	studentFaqHelpLink:"/help/",
	//Admin Faq
	saveFailedFaqError:"Failed to add FAQ.",
	saveFailedEditFaqError:"Failed to edit FAQ.",
	saveFailedAddQuestion:"Failed to add Question.",
	saveFailedEditQuestion:"Failed to edit Question.",
	ReactQuillModules : {
		toolbar: [
			[{ 'header': [1, 2, false] }],
			['bold', 'italic', 'underline','strike', 'blockquote'],
			[{ 'list': 'ordered' }, { 'list': 'bullet' }, ],
			['link', ],
		],
	},
	ReactQuillFormats : [
		'header',
		'bold', 'italic', 'underline', 'strike', 'blockquote',
		'list', 'bullet', 
		'link', 
	],
	AddCaseStudyFailed:"Failed to save case study",
	DeliverableText:"deliverable",
	CategoryRequired:"Select category",
	//locale property is used on Table to display the text if there is no data displayed on table.
	locale : { emptyText: " " },
	mimimumCharactersPopup:30,//Minimum characters for the Complete, Pause and Mark for later popup is set to 30
	minimumCharactersQuestionIndex:20,//Minimum characters for Question Index title is set to 20
	//Footer
	footerText:[<span>An Advanced Review of Speech-Language Pathology, 6th Edition: Practice
		Examinations <span onClick={()=>{window.open("https://www.whatsmybrowser.org/", "_blank");}}>©</span> {year+1} PRO-ED, Inc. All rights reserved.</span>],
	//Menu link constants
	AboutPraxisLink:"/about-the-praxis-slp-ets/",
	AcknowledgementsLink:"/acknowledgements/",
	ReferenceLink:"/references.php",
	TipsPassLink:"https://www.youtube.com/watch?v=mqHra_E8Q2Y",
	unSavedChangesFullLengthMessage:"If you click the OK button, the test will be automatically completed. You will not be able to resume it later. Unanswered questions will be marked as incorrect. Click Cancel if you wish to continue answering the test.",
	unSavedChangesMessage:"If you click the OK button, the test will be automatically paused. You will be able to resume it later from My Tests page. Click Cancel if you wish to continue answering the test.",
	unSavedChangeTutorialMessage:"If you click the OK button, the tutorial will be automatically completed. You will not be able to resume it later. Unanswered questions will be marked as incorrect. Click Cancel if you wish to continue answering the tutorial.",
	EditQuestion:"Edit Question",
	AddQuestion:"Add Question",
	ShortLengthLogoutText:"A test is in progress. You need to either pause it or complete it before logging out.",
	FullLengthLogoutText:" A full-length test is in progress. You need to complete it before logging out.",
	TutorialLogoutText:" A tutorial is in progress. You need to complete it before logging out.",
	//Question of the day Popup
	QODPopupText:"Would you like to answer today's question?",
	QODPopupText2:"You could earn a point.",
	QODPopupDescription:"Click on the OK button to answer ",
	//Terms and Conditions PDF
	PDFTermsAndConditionsLink1:"https://www.proedsoftware.com/pdfs/Advanced_Review_terms_of_use.pdf",
	PDFTermsAndConditionsLink2:"https://www.proedsoftware.com/pdfs/Advanced_Review_eula.pdf",
	PDFTermsAndConditionsLink3:"https://www.proedsoftware.com/pdfs/Advanced_Review_privacy_policy.pdf",
	TermsOfUseText:"I have read, agree with and understand the PRO-ED Website Terms of Use.",
	EULAText:"I have read, agree with and understand the PRO-ED End User License Agreement.",
	PrivacyPolicyText:"I have read, agree with and understand the PRO-ED Website Privacy Policy.",
	//Compare score chart colors
	//Full length chart color
	TestScoreColor:"#000080",
	//FPP
	FPPDarkColor:"#00AAB5",
	FPPLightColor:"#ABCEFF",
	//SAED
	SAEDarkColor:"#44A13F",
	SAEDLightColor:"#A6CBA6",
	//PIET
	PIETDarkColor:"#A70050",
	PIETLightColor:"#E9BCCF",
	//MIXED
	MIXEDDarkColor:"#BC3A00",
	MIXEDLightColor:"#EBC7B6",
	//TOPIC
	TopicDarkColor:"#513BC0",
	TopicLightColor:"#AB9BFD",
	//QOD
	QODCorrectColor:"#3F9200",
	QODIncorrectColor:"#D62900",
	QODMissedColor:"#A2ABB5",
	//Progress Bar Welcome Card Colors
	//My Test Progress Bar Color
	MyTestColor:"#0E0E0F",
	//Flashcard
	Flashcard:"#CC5500",
	//Tutorial
	Tutorial:"#FFC000",
	//FLT
	Fulllength:"#808000",
	//Progress graph dashboard chart color
	AttemptedColor:"#A70050",
	NotAttemptedColor:"#D3D3D3",
	// Test Results
	testResults : "Test Results",
	flashcardResults : "Flashcard Results",
	tutorialResults : "Tutorial Results",
	//Duplicated Tabs or browser login scenarios text
	LoggedInAnotherBrowser:"You have been automatically logged out. It appears that you may be logged in another browser.",
	GeneralErrorMessage:"In any other condition: You have been automatically logged out. You may login again to continue.",
	BrowserVersionText:[<p>This website is best viewed using latest versions of Chrome, Firefox, Safari, Microsoft Edge or a equivalent browser with a screen resolution of 1024 * 768 or higher.</p>,
		<p>It appears that your browser is of older version. You may have trouble viewing our website or few features may not appear properly.</p>],
	ScorePercentage:"Score Percentage",
	//previous year date
	lastYearEndDate : moment(lastYear).endOf('year').format("YYYY-MM-DD"),
	lastYearStartDate : moment("2015-01-01").format("YYYY-MM-DD"),
	//current year startDate end date
	startDateOfTheYear : moment([year]).format("YYYY-MM-DD"),
	endDateOfTheYear : moment([year]).endOf('year').format("YYYY-MM-DD"),
	positiveFeedback : "Excellent, Very Good, Good (no comments)",
	negetiveFeedback : "Excellent, Very Good, Good (with comments), Fair (with/without comments)",
	//Feedback notification 
	feedbackNotificationMessage:"Earn 25 points on your 1st feedback",
	feedbackNotificationDescription1:"Share your thoughts & experience.",
	feedbackNotificationDescription2:"We`d love to hear from you.",
	//Date Filter
	currentMonthSD: moment().startOf('month').format('YYYY-MM-DD'), 
	currentMonthED: moment().endOf('month').format('YYYY-MM-DD'), 
	lastMonthSD: moment().subtract(1, 'months').startOf('month').format('YYYY-MM-DD'), 
	lastMonthED: moment().subtract(1, 'months').endOf('month').format('YYYY-MM-DD'), 
	lastThreeMonthED: currentMonthStartDate.clone().subtract(1, 'days'), 
	lastThreeMonthSD: currentMonthStartDate.clone().subtract(3, 'months'), 
	currentMonthLastYearSD: moment().subtract(1, 'year').startOf('month').format('YYYY-MM-DD'), 
	currentMonthLastYearED: moment().subtract(1, 'year').endOf('month').format('YYYY-MM-DD'), 
	startDateOfTheLastYear : moment().subtract(1, 'years').startOf('year').format("YYYY-MM-DD"),
	endDateOfTheLastYear : moment().subtract(1, 'years').endOf('year').format("YYYY-MM-DD"),
	OptionstoDisplayValues:{
		"1":"3",
		"2":"4",
		"3":"5",
		"4":"6",
		"5":"7",
		"6":"8",
		"7":"9",
		"8":"10",
	},
	changeMailText:[<p>For successful delivery of emails, please add <a className="red-color-time nowrap font-small" href="mailto:AdvancedReviewPractice@proedinc.com">AdvancedReviewPractice@proedinc.com</a> to your address book.</p>],
	usCountryId:'238',
	usCountryCode:'US',
	//email messages for card
	//If primary email address bounces
	primaryEmail:[<div>
		<p>Emails sent to your primary email id are not deliverable. Please email us with any other email id (not yahoo, hotmail, AOL or outlook).</p>
	</div>],
	//If alternate email address bounces
	alternateEmail:[<div>
		<p>Emails sent to your alternate email id are not deliverable. Please update your profile with another email id (not yahoo, hotmail, AOL or outlook).</p>
	</div>],
	// If both email address bounces
	bothEmailBounces:[<div>
		<p>Emails sent to your registered email ids are not deliverable. Please email us on <a className="red-color-time nowrap font-small" href="mailto:AdvancedReviewPractice@proedinc.com">AdvancedReviewPractice@proedinc.com</a> with any other email id (not yahoo, hotmail, AOL or outlook).</p>
	</div>],
	ResendBtn:"Resend",
	ResendActvationTT:"Resend activation link",
	CopyResendActivationText:"Click to copy the activation link",
	NewSuccessResendMessage:"New link for activation has been sent to the user.",
	FLTModalInstructionMessage: [<div>
		<p>You will have two hours and thirty minutes to complete this test.</p>
		<p>This test cannot be paused and must be completed in one attempt only. Once attempted, the test cannot be retaken.</p>
	</div>],
	ActiveUsers:"Active Users",
	LatestRegistrations:"Latest Registrations"
};
export default constants;
